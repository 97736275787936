<template>
  <div id="addModify">
    <a-page-header
      :title="this.$route.query.isActive == 1 ? '添加用户' : '编辑用户'"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <!-- {{ userPrimaryKey }} -->
    <a-row style="margin-top: 50px">
      <a-col :span="12">
        <a-form-model-item
          ref="userAccount"
          :labelCol="{ span: 5 }"
          :wrapperCol="{ span: 17 }"
          label="账号"
          prop="userAccount"
        >
          <a-input
            v-model="userAccount"
            :disabled="this.$route.query.isActive == 1 ? false : true"
            placeholder="输入登录账号精确查询"
            @blur="
              () => {
                $refs.userAccount.onFieldBlur();
              }
            "
          />
          <div style="color: #999">请先在账户管理中创建后,可通过账户进行精确查询用户</div>
          <div style="color: #999">编辑时不可修改,一个账户只可以在数据分析平台存在一个</div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-button
          @click="searchPhone"
          :disabled="this.$route.query.isActive == 1 ? false : true"
          type="primary"
          icon="search"
        >
          查询
        </a-button>
      </a-col>
    </a-row>

    <a-row>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-col :span="12">
          <a-form-model-item ref="userName" :labelCol="labelCol" :wrapperCol="wrapperCol" label="姓名" prop="userName">
            <a-input
              v-model="form.userName"
              :disabled="permanentDisable"
              placeholder="请输入"
              @blur="
                () => {
                  $refs.userName.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="查看全部报告" prop="allReport">
            <a-radio-group v-model="form.allReport" @change="radioChange">
              <a-radio :disabled="Disable" :value="0"> 是 </a-radio>
              <a-radio :disabled="Disable" :value="1"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            v-if="($route.query.isActive == 1 && !radioIsActive) || ($route.query.isActive == 2 && !radioIsActive)"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="报告标签"
            prop="reportLableInfo"
          >
            <a-select
              mode="multiple"
              placeholder="请选择"
              :disabled="Disable || radioIsActive"
              v-model="form.reportLableInfo"
              style="width: 100%"
              :maxTagCount='1'
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="UserLabelFn"
            >
              <a-select-option v-for="(v, i) in userType('报告标签')" :key="i" :value="v.labelId"
                >{{ v.labelName }}
              </a-select-option>
            </a-select>
            <span style="color: #999">多选可全选,用户可查看此标签的报告</span>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            ref="userPhone"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="联系方式"
            prop="userPhone"
          >
            <a-input
              v-model="form.userPhone"
              :disabled="permanentDisable"
              placeholder="请输入"
              @blur="
                () => {
                  $refs.userPhone.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="($route.query.isActive == 1 && !radioIsActive) || ($route.query.isActive == 2 && !radioIsActive)"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="用户标签"
            prop="userLableInfo"
          >
            <a-select
              :disabled="Disable || radioIsActive"
              mode="multiple"
              @change="reportLabelChange"
              @blur="blurSelect"
              @search="IsBlur"
              option-filter-prop="children"
              :maxTagCount='1'
              :filter-option="filterOption"
              @keydown.native="keydown($event)"
              @keyup.enter.native="blurSelect"
              v-model.trim="form.userLableInfo"
              placeholder="请选择"
            >
              <a-select-option v-for="(v, i) in userType('用户标签')" :key="i" :value="v.labelId"
                >{{ v.labelName }}
              </a-select-option>
            </a-select>
            <span style="color: #999"
              >多选可全选，没有则新增,标签不可超过12个字 <br />配置后,报告有此标签的用户可查看报告
            </span>
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="状态" prop="userStatus">
            <a-radio-group v-model="form.userStatus">
              <a-radio :disabled="Disable" :value="0"> 启用 </a-radio>
              <a-radio :disabled="Disable" :value="1"> 禁用 </a-radio>
            </a-radio-group>
            <br />
            <span style="color: #999">仅控制用户在数据分析模块的账户权限</span>
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>
    <div class="fotter">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" style="margin-left: 30px" type="primary" :loading="loading" @click="onSubmit">
        确认保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      Disable: true, //禁用
      radioIsActive: false, //是否查看全部报告 =>禁用报告 && 用户 select
      isBlurType: false, //判断是输入还是选择
      blurText: "", //失去焦点获取文案
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      permanentDisable: true, //name -- phone  永久禁用
      userAccount: "", //用户账号
      //标识符 =>用户直接点击确认时 判断是否 进行账号查询
      identifier: false,
      form: { userStatus: 0, allReport: 1 },
      rules: {
        allReport: [{ required: true, message: "请选择是否查看全部报告", trigger: "change" }],
        userLableInfo: [{ required: true, message: "请选择用户标签", trigger: "change" }],
        reportLableInfo: [{ required: true, message: "请选择报告标签", trigger: "change" }],
        userStatus: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapState({
      dataList: (state) => state.label.dataArr,
      userPrimaryKey: (state) => state.userManagement.userPrimaryKey,
      userfindData: (state) => state.userManagement.userfindData,
    }),
  },

  created() {
    this.labelManagement({ isAll: 1 });
    this.Disable = JSON.parse(this.$route.query.Disable);
    //2 修改 调用 数据回显
    if (this.$route.query.isActive == 2) {
      let id = JSON.parse(this.$route.query.id);
      this.getUserFindByIdFn({
        id,
        cb: () => {
          if (this.userfindData && this.userfindData.userLableInfo && this.userfindData.userLableInfo != null) {
            this.userfindData.userLableInfo = this.userfindData.userLableInfo.split(",");
          } else {
            delete this.userfindData.userLableInfo;
          }
          if (this.userfindData.reportLableInfo && this.userfindData.reportLableInfo != null) {
            this.userfindData.reportLableInfo = this.userfindData.reportLableInfo.split(",");
          } else {
            delete this.userfindData.reportLableInfo;
          }
          // 是否查看全部报告  0 是 1否
          if (this.userfindData.allReport == 0) {
            this.radioIsActive = true;
            //删除报告&&用户校验
            delete this.rules.userLableInfo;
            delete this.rules.reportLableInfo;
          }
          if (this.userfindData && this.userfindData.userAccount) {
            this.userAccount = this.userfindData.userAccount;
          }
          console.log(this.userfindData);
          this.form = this.userfindData;
        },
      });
    }
  },
  methods: {
    ...mapActions({
      labelManagement: "label/labelManagement", //所有标签数据
      createLabel: "label/createLabel", //新建标签
      getUserPhoneFn: "userManagement/getUserPhoneFn", //所有用户信息
      addUserinsertFn: "userManagement/addUserinsertFn", //添加用户信息
      getUserFindByIdFn: "userManagement/getUserFindByIdFn", //点击修改获取用户信息
      userUpadateFn: "userManagement/userUpadateFn", //修改用户信息
    }),

    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },
    //通过账号查询用户
    searchPhone() {
      if (this.userAccount.trim().length == 0) {
        this.warning("账号不能为空");
      } else {
        this.getUserPhoneFn({
          usernumber: this.userAccount.trim(),
          cb: () => {
            //id存在 => 成功回调
            this.Disable = false; //数据返回后赋值并解除禁用
            this.identifier = true;
            this.success("查询成功");
            this.form.userStatus = 0; //查询后 状态默认值
            this.form.allReport = 1;
            //添加校验
            console.log(this.userPrimaryKey);
            this.form.userName = this.userPrimaryKey.name ? this.userPrimaryKey.name : this.userPrimaryKey.nickname;
            this.form.userPhone = this.userPrimaryKey.phoneNumber ? this.userPrimaryKey.phoneNumber : "未填写";
            this.form.roleId = this.userPrimaryKey.id;
            this.rules.userLableInfo = [{ required: true, message: "请选择用户标签", trigger: "blur" }];
            this.rules.reportLableInfo = [{ required: true, message: "请选择报告标签", trigger: "blur" }];
            // this.rules.allReport = [{ required: true, message: "请选择是否查看全部报告", trigger: "change" }];
            this.$forceUpdate();
          },
          cbError: () => {
            //id不存在 => 失败回调
            this.warning("当前账号不存在");
            this.form = {};
            this.Disable = true;
            this.radioIsActive = false;
            this.identifier = false;
            //删除报告&&用户校验&&是否
            delete this.rules.userLableInfo;
            delete this.rules.reportLableInfo;
            //清空ruleForm校验
            this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
          },
        });
      }
    },
    //过滤用户
    userType(types) {
      let arr = this.dataList.filter((item) => {
        return item.labelType == types;
      });
      return arr;
    },

    //模糊查询根据当前得数据返回input中相匹配得值.
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    //用户标签 change事件
    reportLabelChange(value) {
      this.isBlurType = false;
      this.form.userLableInfo = value;
    },
    //报告标签 change事件
    UserLabelFn(value) {
      this.form.reportLableInfo = value;
    },
    //标识符 => 点击还是输入
    IsBlur(value) {
      this.isBlurType = true;
      this.blurText = value;
    },
    // 禁止输入空格
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
        this.warning("标签不能为空");
      }
    },
    //失去焦点 &&  回车事件
    blurSelect() {
      if (this.blurText.length > 12) {
        this.warning("标签不可超过12个字");
        this.blurText = "";
      } else {
        if (this.isBlurType && this.blurText.length > 0) {
          console.log(this.blurText);
          this.createLabel({
            form: { labelType: "0", labelName: this.blurText.toString() },
            cb: (cb) => {
              this.success(cb);
              this.blurText = "";
              this.isBlurType = false;
            },
            cbError: (cb) => {
              this.warning(cb);
              this.blurText = "";
              this.isBlurType = false;
            },
          });
        }
      }
    },

    //单选校验
    radioChange(value) {
      //0 => 是   1=>否
      this.radioIsActive = value.target.value == 0 ? true : false;
      //清空报告&&用户标签
      if (this.radioIsActive) {
        delete this.form.userLableInfo;
        delete this.form.reportLableInfo;
      }else{
         this.rules.userLableInfo = [{ required: true, message: "请选择用户标签", trigger: "blur" }];
            this.rules.reportLableInfo = [{ required: true, message: "请选择报告标签", trigger: "blur" }];
      }
    },
    //提交
    onSubmit() {
      if (!this.identifier && this.$route.query.isActive == 1) {
        this.warning("请先输入账号进行查询");
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            //1 新增  2修改
            // if (!this.identifier.isfier) {
            if (this.$route.query.isActive == 1) {
              this.form.reportLableInfo = this.form.reportLableInfo ? this.form.reportLableInfo.join(",") : "";
              this.form.userLableInfo = this.form.userLableInfo ? this.form.userLableInfo.join(",") : "";
              this.addUserinsertFn({
                form: this.form,
                cb: () => {
                  setTimeout(() => {
                    this.success("添加成功");
                    this.$router.push({ path: "/userManagement/user" });
                  }, 0);
                },
                cbError: (msg) => {
                  this.warning(msg);
                  // this.identifier.isfier = true;
                  if (this.form.reportLableInfo && this.form.reportLableInfo != null) {
                    this.form.reportLableInfo = this.form.reportLableInfo.split(",");
                  } else {
                    delete this.form.reportLableInfo;
                  }
                  if (this.form.userLableInfo && this.form.userLableInfo != null) {
                    this.form.userLableInfo = this.form.userLableInfo.split(",");
                  } else {
                    delete this.form.userLableInfo;
                  }
                },
              });
            } else {
              this.form.reportLableInfo = this.form.reportLableInfo ? this.form.reportLableInfo.join(",") : "";
              this.form.userLableInfo = this.form.userLableInfo ? this.form.userLableInfo.join(",") : "";
              this.userUpadateFn({form:this.form,
               cb:()=>{
                 setTimeout(() => {
                   this.success("修改成功");
                   this.$router.push({ path: "/userManagement/user" });
                 }, 0);
                
              },
                cbError:(msg)=>{
                  this.warning(msg)
                }
              },
             );
            }
          }
        });
      }
    },
    //取消
    handleCancel() {
      this.$router.push({ path: "/userManagement/user" });
    },
  },
};
</script>

<style scoped>
::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.fotter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
